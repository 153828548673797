@layer components {

	.search-dialog[open] {
		display: flex;
	}

	.search-more:not(.hidden) {
		display: block;
	}

}
