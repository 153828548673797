.prose {

	* + h2,
	* + h3 {
		margin-top: calc(2 * var(--gutter));
	}

	h2 + h3,
	* + h4,
	* + figure,
	* + table,
	* + blockquote {
		margin-top: calc(2 * var(--ws));
	}

	* + p,
	* + ul.unstyled,
	* + iframe  {
		margin-top: 1.1666em;
	}

	* + ul:not(.unstyled),
	* + ol {
		margin-top: 0.5666em;
	}

	h5 + p {
		margin-top: 0;
	}

	h2 {
		@apply text-2xl;
	}

	h3 {
		@apply text-xl;
	}

	h4, h5 {
		font-weight: 700;
	}

	ul:not(.unstyled),
	ol {
		display: table;
		border-spacing: 0 0.6em;
		border-collapse: separate;
		list-style-type: none;
	}

	ul:not(.unstyled) > li,
	ol > li {
		position: relative;
		display: table-row;
	}

	ul:not(.unstyled) > li::before,
	ol > li::before {
		display: table-cell;
		width: 1.25em;
	}

	ul:not(.unstyled) > li::before {
		@apply absolute inline-block left-[-1em] font-normal;
		content: "•";
	}

	ol {
		counter-reset: item;
	}

	ol > li {
		counter-increment: item;
	}

	ol > li::before {
		content: counters(item, ".") ". ";
	}

	li ol > li::before {
		content: counters(item, ".") " ";
	}

	a:not(.button, .unstyled, .icon-triangle) {
		text-decoration: underline;
		text-decoration-thickness: 1.5px;
		text-underline-offset: 2px;
	}

	a:not(.button, .unstyled, .icon-triangle):hover {
		background: linear-gradient(
			to bottom, 
			var(--theme-canvas), 
			var(--theme-canvas) 5%, 
			var(--theme-accent) 5%, 
			var(--theme-accent) 85%,
			var(--theme-canvas) 85%,
			var(--theme-canvas) 100%
		);
	}

	.bg-accent  .prose a:not(.button, .unstyled, .icon-triangle):hover {
		background: linear-gradient(
			to bottom, 
			var(--theme-accent), 
			var(--theme-accent) 5%, 
			var(--theme-accent-2) 5%, 
			var(--theme-accent-2) 85%,
			var(--theme-accent) 85%,
			var(--theme-accent) 100%
		);
	}

	audio, video, .plyr {
		margin: calc(3 * var(--ws)) 0;
	}

	hr {
		margin: 0.6em 0 0.8em;
		border: 0;
		border-bottom: 0.1667rem solid var(--theme-accent);
	}

	hr + p,
	hr + ul,
	hr + ol,
	hr + table {
		margin-top: 0;
	}

}

.prose.text-sm {
	hr {
		margin: 0.5rem 0;
	}
}

.prose .button {
	margin: 1.2rem 0;
	background-color: var(--theme-accent);
}

