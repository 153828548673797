@layer components {
	.icon {
		display: inline-block;
		overflow: hidden;
		text-indent: -9999px;
		mask-repeat: no-repeat;
		mask-position: 50% 50%;
		mask-size: contain;
		background: var(--theme-text);
	}

	.icon-shopping {
		mask-image: url(../images/icon-shopping.svg);
	}

	.icon-search {
		mask-image: url(../images/icon-search.svg);
	}

	.icon-a11y {
		mask-image: url(../images/icon-a11y.svg);
	}

	.icon-facebook {
		mask-image: url(../images/icon-facebook.svg);
	}

	.icon-instagram {
		mask-image: url(../images/icon-instagram.svg);
	}

	.icon-twitter {
		mask-image: url(../images/icon-twitter.svg);
	}

}
