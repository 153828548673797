@layer utilities {

	.visually-hidden {
		clip: rect(0 0 0 0); 
		clip-path: inset(50%);
		height: 1px;
		overflow: hidden;
		position: absolute;
		white-space: nowrap; 
		width: 1px;
	}

	.arrow-r,
	.arrow-l {
		display: inline-block;
		height: 1.2ex;
		width: 1.94ex;
		overflow: hidden;
		text-indent: -9999px;
		background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 7.44'%3E%3Cpolygon points='7.42 0 6.94 .72 10.49 3.23 10.49 3.3 0 3.3 0 4.14 10.49 4.14 10.49 4.21 6.94 6.72 7.42 7.44 12 4.07 12 3.36 7.42 0'/%3E%3C/svg%3E") no-repeat 0 0 / 100% auto;
	}

	.arrow-l {
		scale: -1 1;
	}

	a.download::after,
	a[download]::after {
		display: inline-block;
		margin-left: 0.25em;
		content: "";
		height: 1.75ex;
		width: 1.25ex;
		background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 14'%3E%3Cpath d='m0,14v-1.13h10v1.13H0Zm4.56-1.9L1,7.27l1.06-.7,2.33,3.29V0h1.24v9.86l2.33-3.29,1.06.7-3.55,4.83h-.9Z'/%3E%3C/svg%3E") no-repeat 0 0 / 100% auto;
	}

	.compensate-leading {
		position: relative;
		top: 0.2em;
		line-height: 1;
	}

	.marker,
	.letraset-marker > span > span {
		background: linear-gradient(
			to bottom, 
			var(--theme-canvas), 
			var(--theme-canvas) 5%, 
			var(--theme-accent) 5%, 
			var(--theme-accent) 85%,
			var(--theme-canvas) 85%,
			var(--theme-canvas) 100%
		);
	}

	.bg-accent .marker,
	.bg-accent .letraset-marker > span > span {
		background: linear-gradient(
			to bottom, 
			var(--theme-accent), 
			var(--theme-accent) 5%, 
			var(--theme-accent-2) 5%, 
			var(--theme-accent-2) 85%,
			var(--theme-accent) 85%,
			var(--theme-accent) 100%
		);
	}

	.bg-logo {
		background-repeat: no-repeat;
		background-image: url('../images/the-showroom-logo.svg');
		background-size: auto 50%;
		background-position: center;
	}

}
