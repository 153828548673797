@layer components {
	.event-swiper {
		.swiper {
			width: 200%;
			margin-left: -100%;
			padding-top: var(--ws);
		}

		.swiper-slide {
			width: calc(50% - 2 * var(--gutter));
			margin: 0 var(--gutter);
		}

		.swiper-slide:first-child {
			visibility: hidden;
		}

		.swiper-button-lock {
			display: initial;
		}
	}

	@screen md {
		.event-swiper {
			--columns: 3;
			--slide-width: calc((100% - var(--columns) * var(--gutter)) / (var(--columns) - 1));

			.swiper {
				width: calc(100% + var(--slide-width));
				margin-left: calc(-0.5 * (var(--slide-width) + var(--gutter)));
			}

			.swiper-slide {
				width: calc(100% / var(--columns) - var(--gutter));
				margin: 0 calc(var(--gutter) / 2);
			}

			.swiper-slide:first-child {
				visibility: visible;
			}
		}
	}

	@screen lg {
		.event-swiper {
			--columns: 4;
		}
	}

	@screen 2xl {
		.event-swiper {
			--columns: 5;
		}
	}
}
