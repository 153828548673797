@layer components {

	.ratio-box-16\/9 {
		position: relative;
		width: 100%;
		height: 0;
		padding-top: 56.25%;

		& > * {
			position: absolute;
			display: block;
			width: 100%;
			height: 100%;
			inset: 0;
		}

	}

}
