html .snipcart, html .snipcart * {
    /* reset the Snipcart font-family */
    font-family: inherit !important;
}

.snipcart-cart-header svg {
    /* center the icons with OUR font family correctly */
    margin-top: -4px;
}

.snipcart-modal__container {
    max-width: 1600px;
    left: auto;
    right: 0;
    width: 95%;
    z-index: 999;
    @screen md { width: 90%; }
    @screen lg { width: 80%; }

    background: white; /* listen to the global theme? */
    box-shadow: 0 -2px 40px rgba(0,0,0,.2);
}

/*
we must set snipcart buttons color manually and not through snipcart css variables,
because Tailwind reset has higher specificity than snipcart css definition
*/
.snipcart .snipcart-button-primary {
    background: var(--theme-accent, yellow);
    color: var(--theme-accent-text, black);
}
