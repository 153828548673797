@layer components {

	.page-footer {
		--logo-width: 75px;
		--logo-height: 63px;

		.logo {
			display: block;
			overflow: hidden;
			width: var(--logo-width);
			height: var(--logo-height);
			text-indent: -9999px;
			mask-repeat: no-repeat;
			mask-position: 50% 50%;
			mask-size: contain;
			mask-image: url(../images/the-showroom-logo.svg);
			background: var(--theme-text);
		}

		.support-logo svg {
			height: 100%;
			width: auto;
		}
	}

}
