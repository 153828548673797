@layer components {

	.page-header {

		--logo-width: 75px;
		--logo-height: 63px;
		--header-height: calc(2 * var(--ws) + var(--logo-height));
		position: relative;
		z-index: 9;
		width: 100%;
		padding: var(--ws) var(--gutter);
		transition: 0.2s transform;
		will-change: transform;

		.logo {
			display: block;
			overflow: hidden;
			width: var(--logo-width);
			height: var(--logo-height);
			text-indent: -9999px;
			background: var(--theme-text);
			mask-image: url(../images/the-showroom-logo.svg);
		}

		.menu-state {
			display: none;
		}

		.menu-button {
			position: fixed;
			inset: 0 0 auto auto;
			z-index: 99;
			display: flex;
			flex-wrap: wrap;
			align-content: space-between;
			box-sizing: content-box;
			width: 2rem;
			height: 1.25rem;
			padding: calc(2 * var(--ws));
			cursor: pointer;
		}

		.menu-button span,
		.menu-button::before,
		.menu-button::after {
			content: "";
			overflow: hidden;
			display: block;
			width: 2rem;
			height: 2px;
			background: var(--theme-accent-text);
			text-indent: -9999px;
			transition: rotate 0.2s;
		}

		.menu-state:checked + .menu-button span {
			display: none;
		}

		.menu-state:checked + .menu-button::before,
		.menu-state:checked + .menu-button::after {
			position: absolute;
			top: calc(50% - 1px);
			left: calc(2 * var(--ws) - 1px);
			width: 2.15rem;
			display: block;
			content: "";
			background: var(--theme-accent-text);
			rotate: 32deg;
			transform-origin: center;
		}

		.menu-state:checked + .menu-button::after {
			rotate: -32deg;
		}

		.header-menu {
			position: fixed;
			inset: var(--header-height) 0 0 0;
			z-index: 98;
			overflow: hidden;
			box-sizing: border-box;
			width: 100%;
			height: 0;
			padding: 0 calc(3 * var(--ws));
			background: var(--theme-accent);
			transition: height 0.2s;
		}

		.menu-state:checked ~ .header-menu {
			height: calc(100dvh - var(--logo-height));
		}

		.header-menu-primary,
		.header-menu-secondary,
		.header-ui {
			@apply max-w-screen-sm;
			margin: calc(2 * var(--gutter)) auto 0;
		}

		.header-menu-primary li,
		.header-menu-secondary li {
			margin-top: var(--ws);
		}

		.header-submenu {
			margin-top: calc(2 * var(--ws));
		}

		.header-ui {
			margin-top: calc(1.5 * var(--ws) );
			display: flex;
			gap: var(--ws);
		}

		.header-menu-primary a:hover,
		.header-menu-secondary a:hover {
			position: relative;
		}

		.header-menu-primary a:hover::after,
		.header-menu-secondary a:hover::after {
			content: "";
			display: block;
			position: absolute;
			inset: 0;
			bottom: 0.1em;
			width: 100%;
			border-bottom: 1px solid var(--theme-text);
		}

		.header-menu-primary a:hover::after {
			border-bottom-width: 1.5px;
		}

	}

	@screen lg {

		.page-header {
			display: grid;
			grid-template-columns: 6fr 1fr 4fr 1fr;
			grid-auto-flow: row dense;

			.header-logo {
				grid-column: 2;
			}

			.menu-button {
				display: none;
			}

			.header-menu {
				position: static;
				display: contents;
			}

			.header-menu-primary {
				position: relative;
				grid-column: 1;
				grid-row: 1;
				margin: var(--ws) 0 0;
				text-align: left;
			}

			.header-menu-secondary {
				grid-column: 3;
				grid-row: 1;
				margin: calc(1.1875 * var(--ws)) 0 0;
				text-align: right;
			}

			.header-menu-primary li,
			.header-menu-secondary li {
				display: inline-block;
				height: 100%;
			}

			.header-menu-primary li {
				margin: 0;
				padding: 0 var(--ws);
			}

			.header-menu-secondary li {
				margin: 0;
				padding: 0 calc(0.5 * var(--ws));
			}

			.header-menu-primary li:first-child {
				padding-left: 0;
			}

			.header-submenu {
				position: absolute;
				left: -100%;
				width: 100%;
				padding: var(--ws) 0;
				margin: 0;
				opacity: 0;
				transition: opacity 0.2s;
			}

			.header-menu-primary:not(:hover) .is_active .header-submenu,
			.has-submenu:hover .header-submenu {
				left: 0;
				opacity: 1;
			}

			.header-ui {
				margin-top: var(--ws);
				justify-content: right;
			}
		}

	}

}

@screen xl {

	.page-header {
		--header-height: calc(3 * var(--ws) + var(--logo-height));
		padding: calc(1.5 * var(--ws)) var(--gutter);

		.header-menu-secondary {
			margin-top: calc(2 * var(--ws))
		}

		.header-ui {
			margin-top: calc(1.684 * var(--ws));
		}

	}

}

.headroom--pinned.headroom--not-top {
	position: fixed;
	top: 0;
	transform: translateY(0%);
}

.headroom--unpinned {
	transform: translateY(-100%);
}
