@layer components {

	.header-support {
		display: block;
		position: absolute;
		right: var(--gutter);
		bottom: -35px;
		z-index: 999;
		display: block;
		width: 50px;
		height: 50px;
		position: absolute;
		background: transparent url('../images/support-button.png') no-repeat 50% 50% / contain;
	}

	@screen md {
		.header-support {
			bottom: -50px;
			width: 70px;
			height: 70px;
		}
	}

	@screen xl {
		.header-support {
			bottom: -60px;
			width: 85px;
			height: 85px;
		}
	}

}
