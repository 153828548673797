.plyr {
	--plyr-color-main: var(--theme-accent);
	--plyr-audio-controls-background: var(--theme-accent-text);
	--plyr-audio-control-color: var(--theme-canvas);
	--plyr-video-control-color: var(--theme-canvas);
	--plyr-audio-control-background-hover: var(--theme-accent-text);
	--plyr-video-control-background-hover: var(--theme-accent-text);
	--plyr-audio-control-color-hover: var(--theme-accent);
	--plyr-video-control-color-hover: var(--theme-accent);
}

.plyr--audio .plyr__controls{
	border-radius: 9999px;
}
