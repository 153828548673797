@layer components {
	.button {
		@apply text-xl shadow;
		display: inline-block;
		padding: 0.35em 0.5em 0.3em;
		line-height: 1;
	}

	.button-sm {
		@apply text-lg shadow;
		display: inline-block;
		padding: 0.35em 0.5em 0.3em;
		line-height: 1;
	}

	.button[disabled],
	.button-sm[disabled],
	.button.disabled,
	.button-sm.disabled {
		cursor: default;
		pointer-events: none;
		opacity: 0.5;
	}

	.button:focus-visible,
	.button-sm:focus-visible {
		background-color: initial;
		color: initial;
		outline: 2px solid currentcolor;
	}

	@screen notouch {

		.button:hover,
		.button-sm:hover {
			@apply shadow-sm;
			transform: translate(0.05em, 0.05em);
		}

	}

}
