@layer components {

	.cart-button {
		display: none;

		&[visible] {
			display: block;
			@apply scale-100 opacity-100;
		}
	}

	.cart-count {
		@apply absolute inset-0 text-[10px] tracking-tighter top-[2px] flex items-center justify-center pointer-events-none;
	}
}
