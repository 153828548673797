/* default */
:root {
	--theme-canvas: var(--white);
	--theme-canvas-2: var(--faint-grey);
	--theme-border-light: var(--light-grey);
	--theme-text: var(--black);
	--theme-text-2: var(--grey);
	--theme-accent: var(--yellow);
	--theme-accent-2: var(--faint-yellow);
	--theme-accent-3: var(--light-bistre);
	--theme-accent-border-light: var(--light-bistre);
	--theme-accent-border-dark: var(--bistre);
	--theme-accent-text: var(--black);
}

body {
	color: var(--theme-text);
	background: var(--theme-canvas);
}

[data-theme="light"] {
	--theme-accent: var(--faint-grey);
	--theme-accent-2: var(--faint-grey);
	--theme-accent-3: var(--grey);
	--theme-accent-border-light: var(--grey);
	--theme-accent-border-dark: var(--black);
	--theme-accent-text: var(--black);
}

[data-theme="dark"] {
	--theme-canvas: var(--black);
	--theme-canvas-2: var(--darker-grey);
	--theme-border-light: var(--dark-grey);
	--theme-accent: var(--black);
	--theme-accent-2: var(--darker-grey);
	--theme-accent-3: var(--black);
	--theme-accent-text: var(--white);
	--theme-accent-border-light: var(--dark-grey);
	--theme-accent-border-dark: var(--dark-grey);
	--theme-text: var(--white);
	--theme-text-2: var(--grey);
}

.stroke-text {
	stroke: var(--theme-text);
}

.fill-text {
	fill: var(--theme-text);
}

.stroke-accent-text {
	stroke: var(--theme-accent-text);
}

.fill-accent-text {
	fill: var(--theme-accent-text);
}

.bg-accent .button {
	@apply shadow-accent-border-dark;
}

.bg-accent .border-if-same {
	border: 1px solid var(--theme-accent-text);
}
