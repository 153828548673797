@layer components {

	.newsletter-title {
		@apply text-xl;
	}

	.newsletter-submit {
		margin-top: 0.6rem;
	}

	.newsletter-facade-button:focus-visible {
		padding: 0.3em 0.3em 0.2em;
		background: var(--theme-accent-text);
		color: var(--theme-accent);
		outline: 0;
	}

	.newsletter-facade-button {
		text-decoration: underline;
		text-decoration-thickness: 1.5px;
		text-underline-offset: 2px;
	}

	.newsletter-facade-button:hover {
		background: linear-gradient(
			to bottom, 
			var(--theme-canvas), 
			var(--theme-canvas) 5%, 
			var(--theme-accent) 5%, 
			var(--theme-accent) 85%,
			var(--theme-canvas) 85%,
			var(--theme-canvas) 100%
		);
	}

	.bg-accent {

		.newsletter-facade-button:hover {
			background: linear-gradient(
				to bottom, 
				var(--theme-accent), 
				var(--theme-accent) 5%, 
				var(--theme-accent-2) 5%, 
				var(--theme-accent-2) 85%,
				var(--theme-accent) 85%,
				var(--theme-accent) 100%
			);
		}

	}

	.newsletter-dialog[open] {
		display: flex;
	}

}

.newsletter-success,
[class*="newsletter-error"],
[data-newsletter="success"] .newsletter-intro,
[data-newsletter*="error"] .newsletter-intro,
[data-newsletter="success"] .newsletter-form-fields {
	display: none;
}

[data-newsletter="success"] .newsletter-success,
[data-newsletter="error-unsubscribed"] .newsletter-error-unsubscribed,
[data-newsletter="error-exists"] .newsletter-error-exists,
[data-newsletter="error-invalid"] .newsletter-error-invalid,
[data-newsletter="error-unknown"] .newsletter-error-unknown {
	display: block;
}
