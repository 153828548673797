@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "./modules/base.css";
@import "./modules/base.form.css";
@import "./modules/components.archive.css";
@import "./modules/components.button.css";
@import "./modules/components.cart-button.css";
@import "./modules/components.event-swiper.css";
@import "./modules/components.icons.css";
@import "./modules/components.newsletter.css";
@import "./modules/components.page-footer.css";
@import "./modules/components.page-header.css";
@import "./modules/components.plyr.css";
@import "./modules/components.prose.css";
@import "./modules/components.ratio-box.css";
@import "./modules/components.rotator.css";
@import "./modules/components.search.css";
@import "./modules/components.snipcart.css";
@import "./modules/components.support-button.css";
@import "./modules/components.theme.css";
@import "./modules/utilities.css";
