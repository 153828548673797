@layer components {
	[data-rotator] {
		position: relative;
		width: 100%;
	}

	[data-rotator] > * {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	[data-rotator] > *:last-child {
		position: relative;
		height: auto;
	}


	[data-rotator] img {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: contain;
		object-position: center;
	}

	[data-rotator] figcaption {
		display: none;
		position: absolute;
		left: 0;
		right: 0;
	}

	[data-rotator] > *:last-child figcaption {
		display: block;
	}

	[data-rotator]	[data-skew] {
		transition: translate 0.2s, rotate 0.2s;
	}

	[data-rotator] [data-skew]:hover {
		translate: 0 0 !important;
		rotate: 0deg !important;
	}

}
