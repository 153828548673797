.archive-ui {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	text-align: right;
	margin-top: calc(-1 * var(--ws));
	margin-bottom: var(--gutter);

	.select {
		@apply m-0 mr-4;
	}

}

.archive-button {
	height: 2em;
	padding-top: 0.25em;
	margin-left: auto;
}

.archive-button-list .archive-button-text-thumbnails,
.archive-button-thumbnails .archive-button-text-list {
	display: none;
}

.archive-icon {
	display: inline-block;
	vertical-align: baseline;
	width: 0.75em;
	height: auto;
}

.archive-updated {
	animation: 0.5s ease-in 0s 1 forwards fadeInList;
}

@keyframes fadeInList {
	from { opacity: 0; }
	to { opacity: 1; }
}

@keyframes fadeInThumbs {
	from { opacity: 0; }
	to { opacity: 1; }
}

.archive-loadmore {
	position: relative;
	height: fit-content;
	padding-top: 100%;
	container-type: inline-size;
	background: var(--theme-accent);
}

.bg-accent .archive-loadmore {
	background-color: var(--theme-canvas);
}

.archive-loadmore:hover {
	background-color: var(--theme-accent-2);
}

.archive-loadmore-button {
	position: absolute;
	inset: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	text-align: center;
	font-size: 1.3333rem;
	line-height: 1.2;
}

@container ( width >= 350px ) {
	.archive-loadmore-button {
		font-size: 1.6667rem;
	}
}

@container ( width >= 450px ) {
	.archive-loadmore-button {
		font-size: 2rem;
		line-height: 1.067;
	}
}

.archive-list {

	display: block;
	border-top: 1px solid var(--theme-border-light);

	figure {
		display: none;
	}

	.archive-row {
		display: grid;
		grid-template-columns: repeat(12, minmax(0, 1fr));
		grid-template-areas:
			"date  date  date  date  date  date  date  date  type  type  type  type"
			"title title title title title title title title title title title title";
		padding: var(--ws) 0;
		margin: 0;
		border-bottom: 1px solid var(--theme-border-light);
	}

	.archive-date {
		grid-area: date;
		@apply text-xs;
	}

	.archive-type {
		grid-area: type;
		text-align: right;
		@apply text-xs;
	}

	.archive-title {
		grid-area: title;
		margin: 0;
		@apply text-xs;
	}

	.archive-loadmore {
		height: auto;
		padding: var(--ws) 0;
		margin-top: -1px;
		outline-color: var(--theme-border-light);
	}

	.archive-loadmore-button {
		position: static;
		display: block;
		@apply text-base;
	}

}

.bg-accent {

	.archive-list,
	.archive-row {
		border-color: var(--theme-accent-border-dark);
	}
	.archive-loadmore {
		outline-color: var(--theme-accent-border-dark);
	}

}

@screen md {

	.archive-list {
		.archive-row {
			grid-template-areas:
				"date date date date title title title title title title type type";
		}

		.archive-date {
			padding-top: 0.225em;
		}

		.archive-title {
			@apply text-sm;
		}

		.archive-type {
			padding-top: 0.225em;
		}
	}

}

@screen xl {

	.archive-list {

		.archive-date {
			@apply text-sm;
		}

		.archive-title {
			@apply text-base;
		}

		.archive-type {
			@apply text-sm;
		}
	}

}

@screen 2xl {

	.archive-list {
		.archive-row {
			grid-template-areas:
				"date date date title title title title title title title title type";
		}

		.archive-date {
			grid-column: 1 / span 3;
		}

		.archive-title {
			grid-column: 4 / span 8;
			grid-row-start: 1;
		}

		.archive-type {
			grid-column: 12 / 12;
		}
	}

}
